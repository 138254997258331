<template>
  <div class="layoutContent">
    <home-title
      :description="description"
      :showpic="
        getConfigVal(curPageConfigAndDefaultPageConfig, 'showPic', 'vIf')
      "
    />
    <KValidationObserver
      ref="observer"
      class="searchBox"
      :class="isWapSearch ? 'd-flex' : ''"
    >
      <div class="searchInfo wapSearchInfo">
        <p class="font-weight-bold wapShow">{{ $g("buttons.search") }}</p>
        <k-form-select
          v-model="queryParams.electionId"
          :options="electionList"
          style="max-width: 200px"
          class="mr-2 mb-1"
        />
        <k-form-select
          v-model="queryParams.jobId"
          :options="positionPreferenceList"
          style="max-width: 200px"
          class="mr-2 mb-1"
        />
        <KValidationProvider
          name="workDateFrom"
          :rules="{ validateDateFormat: true }"
          v-slot="{ errors }"
          class="mr-2 mb-1"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-datepicker
            v-model="queryParams.workDateFrom"
            boundary="window"
          />
        </KValidationProvider>
        <KValidationProvider
          name="workDateTo"
          :rules="{ validateDateFormat: true }"
          v-slot="{ errors }"
          class="mr-2 mb-1"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-datepicker
            v-model="queryParams.workDateTo"
            boundary="window"
          />
        </KValidationProvider>
        <div class="wapFlexShow justify-content-between">
          <k-button @click="search" variant="primary">
            buttons.search
          </k-button>
          <k-button @click="wapSearch" variant="utility">
            buttons.cancel
          </k-button>
        </div>
      </div>
      <div class="searchBtn wapHide">
        <k-button @click="search" variant="primary" class="mr-2">
          buttons.search
        </k-button>
        <k-button @click="clear" variant="default"> buttons.clear </k-button>
      </div>
    </KValidationObserver>
    <b-icon
      icon="search"
      class="wapShow featuredFont"
      @click="wapSearch"
      style="margin-bottom: 10px"
    ></b-icon>
    <div class="contentParty" v-show="!isWapSearch">
      <Nav ref="nav" :tab-index="tabIndex" />
      <div class="cardBody">
        <div class="mb-1 mt-1 text-right wapTextLeft">
          {{ $g("numberOfElectionsWorked") }}:{{ numberOfElectionsWorked.info }}
        </div>
        <adaptiveTable
          :only-table-wap="true"
          :fields="fields"
          :items="tableWapData"
        />
        <konn-grid
          ref="selectableTable"
          :has-action="false"
          :has-check-box="false"
          :fields="fields"
          :data-source="dataSource"
          :get-condition="getCondition"
          @items-get="allGet"
          class="wapTableHide"
        >
          <template #cell(workDatesTimes)="row">
            <div v-for="(item, index) in row.value" :key="index">
              {{ item }}
            </div>
          </template>
        </konn-grid>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from "./components/nav";
import { getPortalPageSetting } from "@/api/home";
import {
  getCurPageConfigAndDefaultPageConfigbyRoutePath,
  getConfigVal,
} from "fe-infrastractures";
import {
  getPositionPreferenceList,
  getWorkHistoryList,
  getWorkElectionCount,
} from "../../api/workTrainingHistory";
import { EnumWorkPortalStatus } from "@/utils/enums/electionStatus";
import { getHistoryElectionList } from "@/api/payment";
import { pageNameEnum } from "@/utils/enums/pageNameEnum";
import adaptiveTable from "../components/adaptive-table.vue";
import HomeTitle from "@/views/components/homeTitle";
export default {
  components: {
    Nav,
    adaptiveTable,
    HomeTitle,
  },
  computed: {
    dataSource() {
      return getWorkHistoryList;
    },
  },
  data() {
    let curPageConfigAndDefaultPageConfig =
      getCurPageConfigAndDefaultPageConfigbyRoutePath(
        "workerPortal",
        this.$route.path
      );
    return {
      curPageConfigAndDefaultPageConfig,
      getConfigVal,
      description: "",
      numberOfElectionsWorked: 0,
      queryParams: {
        electionId: null,
        jobId: null,
        workDateFrom: null,
        workDateTo: null,
      },
      electionList: [],
      positionPreferenceList: [],
      tabIndex: 0,
      fields: [
        {
          key: "electionTitle",
          label: "electionTitle",
          sortable: false,
          width: "15%",
        },
        {
          key: "electionDateStr",
          label: "electionDate",
          sortable: false,
          width: "15%",
        },
        {
          key: "position",
          label: "position",
          sortable: false,
          width: "15%",
        },
        {
          key: "workLocation",
          label: "workLocation",
          sortable: false,
          width: "15%",
        },
        {
          key: "workDatesTimes",
          label: "workDatesTimes",
          sortable: false,
          width: "40%",
        },
      ],
      tableWapData: null,
      isWapSearch: false,
      queryParamsOld: {
        electionId: null,
        jobId: null,
        workDateFrom: null,
        workDateTo: null,
      },
    };
  },
  methods: {
    async search() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.isWapSearch = false;
        this.$refs.selectableTable.refresh();
        this.queryParamsOld = JSON.parse(JSON.stringify(this.queryParams));
      }
    },
    getCondition() {
      return this.queryParams;
    },
    getDescription() {
      getPortalPageSetting(pageNameEnum.WorkHistory).then((resp) => {
        if (resp.data) {
          this.description = resp.data.description;
        }
      });
    },
    bindElectionList() {
      getHistoryElectionList(EnumWorkPortalStatus.published).then((resp) => {
        this.electionList = resp.data || [];
        if (this.electionList.length > 0) {
          this.queryParams.electionId = this.electionList[0].value;
        }
      });
    },
    bindPositionPreferenceList() {
      getPositionPreferenceList().then((resp) => {
        this.positionPreferenceList = resp.data || [];
        if (this.positionPreferenceList.length > 0) {
          this.queryParams.jobId = this.positionPreferenceList[0].value;
        }
      });
    },
    getElectionCount() {
      getWorkElectionCount().then((resp) => {
        this.numberOfElectionsWorked = resp.data || 0;
      });
    },
    allGet(data) {
      this.tableWapData = data;
    },
    wapSearch() {
      this.isWapSearch = !this.isWapSearch;
      if (this.isWapSearch) {
        this.queryParams = JSON.parse(JSON.stringify(this.queryParamsOld));
      }
    },
    clear() {
      this.queryParams.electionId = null;
      this.queryParams.jobId = null;
      this.queryParams.workDateFrom = null;
      this.queryParams.workDateTo = null;
      this.search();
    },
  },
  created() {
    this.bindPositionPreferenceList();
    this.bindElectionList();
    this.getDescription();
    this.getElectionCount();
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 1024px) {
  .wapTextLeft {
    text-align: left !important;
  }
}
// @media (min-width: 1025px) {
//   .contentParty {
//     display: block !important;
//   }
// }
</style>
